<script setup lang="ts">
	
	const props = defineProps<{ disabled?: boolean, stateless?: boolean }>()
	const model = defineModel()
	const emit = defineEmits(['input'])
	watch(model, () => emit('input', model.value), { deep: true })
	
</script>

<template>
	<div class="p-[4px] w-[44px] h-[28px] min-w-[44px] min-h-[28px] rounded-full flex cursor-pointer duration-300 select-none transition-all" :style="disabled === true ? 'filter: grayscale(); opacity: .5; pointer-events: none;' : ''" @click="model = !model" :class="model === false || !model ? (stateless ? 'bg-[#6366f1]' : 'bg-[#f9f9f9]') : 'bg-[#6366f1]'">
		<div class="bg-[#ffffff] h-full aspect-square rounded-full transition-transform duration-300 select-none" :class="{ 'translate-x-[16px]': model }" style="filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.10));"></div>
	</div>
</template>
  

